<template>
  <div id="list">
    <div class="bg-light">
      <div class="container space-2">
        <div class="w-md-85 w-lg-65 text-center mx-md-auto mb-5">
          <h2>{{ $t('AcademyPage.MoreSection.Title') }}</h2>
          <p>{{ $t('AcademyPage.MoreSection.Description') }}</p>
        </div>
        <div class="container">
          <div class="mt-5">
            <div class="row">
              <div v-for="item in contact_list" :key="item.title" class="col-md-4 mb-5 mb-md-0">
                <div class="card h-100">
                  <div class="card-body">
                    <figure class="max-w-6rem w-100 mb-3">
                      <img class="img-fluid" :src="require(`@/assets/svg/icons/${item.icon}.svg`)">
                    </figure>
                    <h4>{{ $t(item.title) }}</h4>
                    <span v-html="$t(item.description)" />
                  </div>
                  <div class="card-footer border-0">
                    <a :href="$t(item.link)" class="font-weight-bold" target="_blank">{{ $t(item.button) }} <i class="fas fa-angle-right fa-sm ml-1" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'More',
  data() {
    return {
      contact_list: [
        {
          title: 'AcademyPage.MoreSection.Card1.Title',
          description: 'AcademyPage.MoreSection.Card1.Description',
          icon: 'icon-40',
          button: 'AcademyPage.MoreSection.Card1.Button',
          link: 'AcademyPage.MoreSection.Card1.Link'
        },
        {
          title: 'AcademyPage.MoreSection.Card2.Title',
          description: 'AcademyPage.MoreSection.Card2.Description',
          icon: 'icon-12',
          button: 'AcademyPage.MoreSection.Card2.Button',
          link: 'AcademyPage.MoreSection.Card2.Link'
        },
        {
          title: 'AcademyPage.MoreSection.Card3.Title',
          description: 'AcademyPage.MoreSection.Card3.Description',
          icon: 'icon-7',
          button: 'AcademyPage.MoreSection.Card3.Button',
          link: 'AcademyPage.MoreSection.Card3.Link'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
