<template>
  <div>
    <Hero />
    <VideoList />
    <APIKnowledgeList />
    <AcademyList />
    <More />
    <CTA />
  </div>
</template>

<script>
import Hero from './components/hero'
import VideoList from './components/video-list'
import APIKnowledgeList from './components/api-knowledge-list'
import AcademyList from './components/academy-list'
import More from './components/more'
import CTA from './components/cta'

export default {
  name: 'Academy',
  components: {
    Hero,
    VideoList,
    APIKnowledgeList,
    AcademyList,
    More,
    CTA
  },
  metaInfo() {
    return {
      title: 'OpenAPIHub',
      titleTemplate: 'Open API Academy | %s',
      link: [
        { rel: 'canonical', href: 'https://www.openapihub.com/academy' }
      ],
      meta: [
        { name: 'description', content: 'Learn how to start your first API journey with our online bootcamp.' },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: 'Open API Academy | OpenAPIHub' },
        { property: 'og:description', content: 'Learn how to start your first API journey with our online bootcamp.' },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/openapihub-2.jpg' },
        { property: 'og:url', content: 'https://www.openapihub.com/academy' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
