<template>
  <div id="list">
    <div>
      <div class="container space-2">
        <div class="w-md-85 w-lg-65 text-center mx-md-auto mb-5">
          <h2>{{ $t('AcademyPage.ApiProducts.Title') }}</h2>
          <p>{{ $t('AcademyPage.ApiProducts.Description') }}</p>
        </div>
        <div class="row">
          <div v-for="(item, index) in List" :key="index" class="col-md-6 col-lg-3 mb-4 mb-md-5">
            <a class="card h-100 text-center" :href="item.url">
              <div class="card-body p-3">
                <figure class="avatar mx-auto mb-4">
                  <img class="avatar-img" :src="require('@/assets/svg/icons/' + item.image + '.svg')">
                </figure>
                <h5 class="mb-0">{{ $t(item.title) }}</h5>
              </div>
              <div class="card-footer border-0 pt-0">
                <small v-if="item.status" class="text-muted">{{ $t(item.status) }}</small>
                <span v-if="item.length" class="font-weight-bold"><i class="far fa-clock mr-1" /> {{ $t(item.length) }} {{ $t('AcademyPage.ApiProducts.Length') }}</span>
              </div>
            </a>
          </div>

          <div class="col-md-6 col-lg-3 mb-4 mb-md-5">
            <a class="card h-100 text-center border-dashed transition-3d-hover" style="background-color:#EAF4FD" href="javascript:;" data-toggle="modal" data-target="#partnerEcoSystemForm">
              <div class="card-body">
                <figure class="avatar mx-auto mb-4">
                  <img class="avatar-img" src="@/assets/svg/icons/icon-30.svg">
                </figure>
                <h4 class="">{{ $t('AcademyPage.ApiProducts.Cta') }}</h4>
              </div>
              <div class="card-footer border-0 pt-0">
                <span class="font-weight-bold">{{ $t('AcademyPage.ApiProducts.CtaButton') }} <i class="far fa-angle-right ml-1" /></span>
              </div>
            </a>
          </div>

          <PartnerEcoSystemForm />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bootstrap'
import List from './list.json'
import PartnerEcoSystemForm from '@/components/partner-ecosystem-form'

export default {
  name: 'AcademyList',
  components: {
    PartnerEcoSystemForm
  },
  data() {
    return {
      List: List.List
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
