<template>
  <div class="form-container">
    <div id="partnerEcoSystemForm" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="partnerEcoSystemFormTitle" aria-hidden="true">
      <div class="modal-lg modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="partnerEcoSystemFormTitle" class="modal-title" />
            <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
              <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="w-md-90 mx-auto mb-6 form-submit">
              <div id="hubspotPartnerEcoSystemForm" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartnerEcoSystemForm',
  mounted() {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)
    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '7091000',
          formId: 'b0759c2b-b8f9-4174-bebe-334b98efcf62',
          errorClass: 'hubspotFormError',
          target: '#hubspotPartnerEcoSystemForm'
        })
      }
    })
  },
  methods: {
    showModal() {
      this.$refs['partnerEcoSystemForm'].show()
    },
    hideModal() {
      this.$refs['partnerEcoSystemForm'].hide()
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-header {
  border-bottom: 0;
}
</style>
