<template>
  <!-- Hero -->
  <div class="bg-img-hero" :style="{'background-image': 'url(' + require('@/assets/svg/components/ben-career-hero.svg') + ')'}">
    <div class="container space-1">
      <div class="row align-items-lg-center">
        <div class="col-lg-7 mb-3 mb-lg-0 text-center text-lg-left">
          <div class="mb-4">
            <h1>{{ $t('AcademyPage.Title') }}</h1>
            <p class="lead">{{ $t('AcademyPage.Description') }}</p>
          </div>
          <a class="btn btn-primary text-white transition-3d-hover" @click="scrollToList">{{ $t('AcademyPage.HeroButton') }}</a>
        </div>

        <div class="col-lg-5">
          <div class="w-60 w-lg-80 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/api-economy-education.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- End Hero -->
</template>

<script>
export default {
  name: 'Hero',
  methods: {
    scrollToList() {
      var elmnt = document.getElementById('oah-list')
      elmnt.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
