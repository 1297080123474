<template>
  <div id="api-knowledge-list">
    <div class="bg-light">
      <div class="container space-2">
        <div class="w-md-85 w-lg-65 text-center mx-md-auto mb-5">
          <h2>{{ $t('AcademyPage.ArticleSection.Title') }}</h2>
          <p>{{ $t('AcademyPage.ArticleSection.Description') }}</p>
        </div>
        <div id="api-essentials" class="mb-9">
          <div class="row align-items-center mb-3">
            <div class="col-sm-6">
              <h3>{{ $t('AcademyPage.ArticleSection.ApiEssentials.Title') }}</h3>
            </div>
            <div class="col-sm-6 text-sm-right">
              <a href="https://blog.openapihub.com/en-us/category/api-essentials/" target="_blank" class="font-weight-bold">{{ $t('AcademyPage.ArticleSection.ViewMore') }} <i class="fas fa-angle-right ml-1" /></a>
            </div>
          </div>
          <div class="row">
            <div v-for="(item, index) in EssentialsList" :key="index" class="col-md-6 col-lg-4 mb-4 mb-md-5">
              <!-- Card -->
              <a :href="$t(item.url)" class="card h-100 transition-3d-hover" target="_blank">
                <img class="card-img-top" :src="require('@/assets/img/academy/' + `${ $t(item.image) }` + '.jpg')">
                <div class="card-body">
                  <h4>{{ $t(item.title) }}</h4>
                  <p class="small mb-0">{{ $t(item.description) }}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div id="api-tutorials">
          <div class="row align-items-center mb-3">
            <div class="col-sm-6">
              <h3>{{ $t('AcademyPage.ArticleSection.ApiTutorials.Title') }}</h3>
            </div>
            <div class="col-sm-6 text-sm-right">
              <a href="https://blog.openapihub.com/en-us/category/api-tutorials/" target="_blank" class="font-weight-bold">{{ $t('AcademyPage.ArticleSection.ViewMore') }} <i class="fas fa-angle-right ml-1" /></a>
            </div>
          </div>
          <div class="row">
            <div v-for="(item, index) in TutorialsList" :key="index" class="col-md-6 col-lg-4 mb-4 mb-md-5">
              <!-- Card -->
              <a :href="$t(item.url)" class="card h-100 transition-3d-hover" target="_blank">
                <img class="card-img-top" :src="require('@/assets/img/academy/' + `${ $t(item.image) }` + '.jpg')">
                <div class="card-body">
                  <h4>{{ $t(item.title) }}</h4>
                  <p class="small mb-0">{{ $t(item.description) }}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import 'bootstrap'
const $ = require('jquery')
import ApiEssentials from './list.json'
import ApiTutorials from './list.json'

export default {
  name: 'ArticlesList',
  data() {
    return {
      EssentialsList: ApiEssentials.ApiEssentials,
      TutorialsList: ApiTutorials.ApiTutorials
    }
  },
  mounted() {
    $('.modal').on('hidden.bs.modal', function(e) {
      var $iframes = $(e.target).find('iframe')
      $iframes.each(function(index, iframe) {
        $(iframe).attr('src', $(iframe).attr('src'))
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.modal-header {
  border-bottom: 0;
}
</style>
