<template>
  <div id="oah-list">
    <div>
      <div class="container space-2">
        <div class="w-md-85 w-lg-65 text-center mx-md-auto mb-5">
          <h2>{{ $t('AcademyPage.VideoSection.Title') }}</h2>
          <p>{{ $t('AcademyPage.VideoSection.Description') }}</p>
        </div>
        <div class="row">
          <div v-for="(item, index) in List" :key="index" class="col-md-6 col-lg-4 mb-4 mb-md-5">
            <!-- Card -->
            <a :id="`${item.id}`" data-gtm-att="ga-academy-video-card" class="card h-100 transition-3d-hover video-btn" href="javascript:;" data-toggle="modal" :data-target="`#${item.modal}`">
              <img class="card-img-top" :src="require('@/assets/img/academy/' + `${ $t(item.image) }` + '.jpg')">
              <div class="card-body">
                <span class="small text-muted"><i class="mr-1" :class="`${item.icon}`" /> {{ item.length }}</span>
                <h4 class="mt-2 mb-0">{{ $t(item.title) }}</h4>
              </div>
            </a>
            <!-- Modal -->
            <div :id="`${item.modal}`" :key="index" class="modal fade" tabindex="-1" role="dialog" :aria-labelledby="`${item.modal}Title`" aria-hidden="true">
              <div class="modal-xl modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 :id="`${item.modal}Title`" class="modal-title" />
                    <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                      <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                      </svg>
                    </button>
                  </div>
                  <div class="modal-body p-2 px-sm-4 pt-0 pb-4">
                    <div style="padding:52% 0 0 0;position:relative;">
                      <iframe id="video" :src="`${ $t(item.video) }`" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" :title="`${ $t(item.title) }`" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-6 col-lg-4 mb-4 mb-md-5">
            <div class="card h-100">
              <img class="card-img-top" src="@/assets/img/images/oah-demo-video-thumbnail.jpg">
              <div class="card-body">
                <span class="small badge badge-soft-secondary">{{ $t('AcademyPage.ComingSoon') }}</span>
                <h4 class="mt-2">{{ $t('AcademyPage.VideoSection.MonetizeSubscription.Title') }}</h4>
              </div>
            </div>
          </div> -->

          <!-- <div class="col-md-6 col-lg-4 mb-4 mb-md-5">
            <div class="card h-100 text-center">
              <div class="my-auto py-4 px-7">
                <div class="max-w-19rem mx-auto mb-2">
                  <img class="img-fluid" src="@/assets/svg/illustrations/replay-video.svg">
                </div>
                <p>More courses are coming soon. Stay Tuned!</p>
              </div>
            </div>
          </div> -->
        </div>
        <div class="text-center mt-3">
          <a :href="$t('AcademyPage.VideoSection.ViewMoreURL')" class="btn btn-primary" target="_blank">{{ $t('AcademyPage.VideoSection.ViewMoreButton') }}</a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import 'bootstrap'
const $ = require('jquery')
import DevelopWithOAH from './list.json'

export default {
  name: 'OAHList',
  data() {
    return {
      List: DevelopWithOAH.DevelopWithOAH
    }
  },
  mounted() {
    $('.modal').on('hidden.bs.modal', function(e) {
      var $iframes = $(e.target).find('iframe')
      $iframes.each(function(index, iframe) {
        $(iframe).attr('src', $(iframe).attr('src'))
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.modal-header {
  border-bottom: 0;
}
</style>
